.carousel-container {
   
    margin: auto;
    box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.1);
    margin-left: 70px;
    margin-right: 70px;
    background-color: black;
    margin-bottom: 10px;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .carousel {
    display: flex;
    transition: transform 1s ease-in-out;
    
  }
 
  
  .carousel-image {
    min-width: 20%;
    max-height: 50vh;
    border: 1px solid white;
    object-fit: fit;
    overflow: hidden;
  }

  /* mobile */

  .carousel-container-Mobile {
    margin: auto;
    box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.1);
    margin-left: 20px;
    margin-right: 20px;
    background-color: black;
    margin-bottom: 10px;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .carousel {
    display: flex;
    transition: transform 1s ease-in-out;
    
  }
  .carousel-new-Mobile {
    display: flex;
    min-width: 50%;
    max-height: 50vh;
    border: 1px solid white;
    object-fit: cover;
    overflow: hidden;
   

  }
  
  .carousel-image-Mobile {
    min-width: 50%;
    max-height: 50vh;
    border: 1px solid white;
    object-fit: cover;
    overflow: hidden;
  }

  /* home carousel */

  .carousel-container-home {
    overflow: hidden;
    margin: auto;
    border: 2px solid #ddd;
   
    background-color: rgb(44, 44, 44);
    margin-bottom: 10px;
    position: relative;
  }
  .carousel-image-home{
    min-width: 100%;
    max-height: 80vh;
    border: 1px solid white;
    object-fit: fit;
  
   
  }

  .carousel-image-deatilpage{
    min-width: 100%;
    height: 60vh;
    border: 1px solid white;
    object-fit: contain;
   
   
  }

  .carousel-image-home-mobile{
    min-width: 100%;
    max-height: 50vh;
    border: 1px solid white;
    object-fit: fit;
  }

  .carousel-image-deatilpage-mobile{
    min-width: 100%;
    max-height: 50vh;
    border: 1px solid white;
    object-fit: contain;
  }

  

  /* imgSlideBtn */

  #imgSlideBtn{
    background-color: rgba(0, 0, 0, 0.685);
    border: none;
    color:white;
    justify-items: center;
    align-items: center;
    height: 100px;
    padding: 5px;
    cursor: pointer;
  }

  #imgSlideBtn:hover{
    transform: scale(90%);
    color: aqua;
  }