

.gameBoard{
    width:300px;
    margin:auto;
    margin-left:20%;
    margin-top:20%;
    display:grid;
    grid-template-columns: repeat(4,1fr) ;
    grid-template-rows: repeat(4,1fr);
    
}

.circle{
    margin:5px;
    border-radius: 50%;
    height:50px;
    width:50px;
}

.evenC{
border: 5px solid black;
background-color: red;
}
.oddC{
    border: 5px solid greenyellow;
    background-color: blue;

}

